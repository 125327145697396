export default {
  GET(state, data) {
    state.label = { ...data }
  },
  LIST(state, data) {
    state.labels = data
  },
  SET_FORM(state, data) {
    state.labelsForm = { ...data }
  },
}

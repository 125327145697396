<template>
  <div>
    <router-view />
  </div>
</template>

<script>

import LabelsFrom from '@/views/settings/fulfillment/labels/components/LabelsFrom.vue'
import store from '@/store'
import labelsModule from '@/store/settings/fulfillment/labels'
import {onUnmounted} from '@vue/composition-api'

export default {
  name: 'Labels',
  components: {LabelsFrom},
  created() {
    this.$emit('updateMenu', 'settings-fulfillment-labels')
  },
  setup() {
    const MODULE_NAME = 'labels'
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, labelsModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
  },
}
</script>

<style scoped>

</style>

<template>
  <div>
    <label class="font-weight-bolder text-dark font-medium-5 mb-1">{{ $t('Box Labels') }}</label>
    <b-row class="d-flex flex-column">
      <b-col
        md="12"
      >
        <div
          v-for="field in ['setting_fulfill_label_box']"
          :key="field"
          style="height: 30px; margin-bottom: 10px"
        >
          <span
            class="font-weight-bolder d-block"
            style="margin-bottom: 5px; margin-left: 10px"
          >{{ fields[field].label }}</span>
          <b-skeleton
            height="150px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BCol, BRow, BSkeleton } from 'bootstrap-vue'
import config from '@/views/settings/fulfillment/labels/LabelsConfig'

export default {
  name: 'Skeletons',
  components: { BCol, BSkeleton, BRow },
  setup() {
    const { fields } = config()
    return {
      fields,
    }
  },

}
</script>
<style scoped lang="scss">

</style>

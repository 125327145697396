<template>
  <div>
    <skeleton v-if="loading" />
    <b-form v-else>
      <div class="d-flex justify-content-end">
        <feather-icon
          v-if="organizationRequiresEditConfirmation"
          v-b-tooltip.noninteractive.hover.bottom
          :title="$t('Edit')"
          :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
          size="36"
          class="ml-1 cursor-pointer"
          @click="isEditAble()"
        />
      </div>
      <validation-observer
        ref="labelCreateVal"
      >
        <label class="font-weight-bolder text-dark font-medium-5 mb-1">{{ $t('Box Labels') }}</label>
        <b-row>
          <b-col md="12">
            <component
              :is="fields['setting_fulfill_label_box'].type"
              v-model="labelsForm['setting_fulfill_label_box']"
              v-bind="getProps('setting_fulfill_label_box')"
              :is-editable="isEdit"
            />
          </b-col>
        </b-row>
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="!isFormChanged"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="routeLink"
              >
                {{ $t('Back to Setting') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="!isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>
<script>
import Skeleton from '@/views/settings/fulfillment/labels/components/Skeletons.vue'
import {
  BButton, BCol, BForm, BRow, VBTooltip,
} from 'bootstrap-vue'
import { required } from '@/libs/validations/validations'
import { ValidationObserver } from 'vee-validate'
import config from '@/views/settings/fulfillment/labels/LabelsConfig'
// eslint-disable-next-line import/no-cycle
import { scrollToError } from '@core/utils/utils'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import store from '@/store'

export default {
  name: 'LabelsFrom',
  components: {
    ErrorAlert,
    BButton,
    BCol,
    BRow,
    ValidationObserver,
    BForm,
    Skeleton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      required,
      error: {},
      loading: true,
      onSubmit: false,
      labels: {},
      labelsClone: {},
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    labelsForm() {
      return this.$store.state[this.MODULE_NAME].labelsForm
    },
    labelsFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].settingFulfillmentLabelFormClone
    },
    isFormChanged() {
      console.log(JSON.stringify(this.labelsForm))
      console.log(JSON.stringify(this.labelsFormClone))
      return JSON.stringify(this.labelsForm) !== JSON.stringify(this.labelsFormClone)
    },
  },
  created() {
    this.load()
  },
  beforeRouteLeave(to, from, next) {
    this.showConfirmationModal(next)
  },
  methods: {
    isEditAble() {
      this.isEdit = !this.isEdit
    },
    routeLink() {
      this.$router.push({ name: 'settings' })
    },
    getProps(fieldName) {
      const dataOF = {
        field: {
          ...this.fields[fieldName],
        },
        name: fieldName,
      }
      return dataOF
    },
    load() {
      this.template = {}
      this.templateClone = {}
      this.$store.dispatch(`${this.MODULE_NAME}/get`).then(res => {
        const { data } = res.data
        this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, data)
        this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_LABEL_FORM_CLONE`, data)
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.error = true
        this.errorNotification(this, err)
      }).finally(() => {
        this.loading = false
      })
    },
    submit() {
      this.onSubmit = true

      this.$refs.labelCreateVal.validate()
        .then(success => {
          if (success) {
            const data = this.labelsForm
            const labelForm = this.mappingFields(Object.keys(this.fields), data)
            this.sendNotification(this, { ...labelForm }, `${this.MODULE_NAME}/update`)
              .then(() => {
                this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, data)
                this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_LABEL_FORM_CLONE`, data)
                // this.$router.replace({
                //   name: 'settings-fulfillment-labels',
                // })
              })
              .catch(err => {
                this.error = err.response?.data
              }).finally(() => {
                this.onSubmit = false
              })
          } else {
            scrollToError(this, this.$refs.labelCreateVal)
          }
        })
    },
    loader() {
      this.$refs.labelCreateVal.reset()
      // const smth = JSON.parse(this.labelsFormClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, this.labelsFormClone)
    },
    clear() {
      this.$refs.labelCreateVal.reset()
    },
    showConfirmationModal(next) {
      this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },

  },

  setup() {
    const MODULE_NAME_CLONE = 'cloneData'
    const MODULE_NAME = 'labels'

    const { fields, ACCESS_ABILITY_FOR_SYSTEM_FULFILLMENT_LABELS } = config()
    return {
      fields,
      MODULE_NAME,
      MODULE_NAME_CLONE,
      ACCESS_ABILITY_FOR_SYSTEM_FULFILLMENT_LABELS,
    }
  },
}
</script>

<style scoped lang="scss">

</style>

import {
    TEXTAREA_INPUT,
} from '@/views/components/DynamicForm/constants'

export default function config() {
  const ACCESS_ABILITY_FOR_SYSTEM_FULFILLMENT_LABELS = { action: 'change', subject: 'Labels' }

  const fields = {
    setting_fulfill_label_box: {
      type: TEXTAREA_INPUT,
      label: 'Template',
      rules: 'required',
      options: {
        rows: 8,
      },
    },

  }

  return {
    fields,
    ACCESS_ABILITY_FOR_SYSTEM_FULFILLMENT_LABELS,
  }
}
